import React, { useState, useEffect } from 'react';
import styles from './ThinDiscountBanner.module.css';

const ThinDiscountBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    const bannerState = localStorage.getItem('discountBannerVisible');
    if (bannerState !== null) {
      setIsVisible(JSON.parse(bannerState));
    }

    // Check if it's the home page, accounting for the '/es/' prefix
    const path = window.location.pathname;
    setIsHomePage(path === '/es/' || path === '/es/index.html');
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('discountBannerVisible', JSON.stringify(false));
  };

  if (!isVisible || isHomePage) return null;

  return (
    <div className={styles.container}>
      <div className={styles.backgroundOverlay}></div>
      <div className={styles.content}>
        <p className={styles.title}>Recibe 30€ en tu Primer Alquiler 💰</p>
        <button className={styles.closeButton} onClick={handleClose} aria-label="Cerrar banner">
          ×
        </button>
      </div>
    </div>
  );
};

export default ThinDiscountBanner;
